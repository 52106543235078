html {
  scroll-behavior: smooth;
}

p {
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 320px) {
  
  /* Navigation */
  .navigation--wrapper {
    display: flex;
    position: sticky;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.85);
  }

  .navigation--img {
    width: 100%;
  }
  

  /* About */

  .about--wrapper {
    padding: 1rem;
  }
  
  .about--personal--description {
    width: 75%;
    line-height: 2;
    padding: 1rem;
    margin-bottom: 2rem;;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }
    
  .about--image {
    display: none;
  }


  /* Blogs */

  .blog--wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  
  
  .blog--grid {
    display: flex;
    flex-direction: column;
  }
  
  .blog--element {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    margin: 1rem 0;
  }
  
  .blog--element--content {
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: space-evenly;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    padding-top: 1rem;
    padding-bottom: 1rem;;
  }

  .blog--element--content--description {
    padding: 1rem 0;
  }
  
  .blog--element--img {
    height: 150px;
  }
  
  .blog--element--content-time {
    display: flex;
    column-gap: 1rem;
  } 
}

@media only screen and (max-width: 540px) {
  
  .navigation {
    display: flex;
    list-style-type: none;
    padding: 1.5rem;
  }

  .navigation--img {
    width: 100%;
  }

  /* Navigation */
  .navigation--wrapper {
    display: grid;
    grid-template-columns: 20% 70%;
    gap: 10%;
    position: sticky;
    top: 0px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.85);
  }


  .navigation--element {
    padding: 0;
  }

  /* About */

  .about--wrapper {
    padding: 1rem;
  }
  
  .about--personal--description {
    width: 75%;
    line-height: 2;
    padding: 1rem;
    margin-bottom: 2rem;;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }
    
  .about--image {
    display: none;
  }


  /* Blogs */

  .blog--wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  
  .blog--grid {
    display: flex;
    flex-direction: column;
  }
  
  .blog--element {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    margin: 1rem 0;
  }
  
  .blog--element--content {
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: space-evenly;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    padding-top: 1rem;
    padding-bottom: 1rem;;
  }

  .blog--element--content--description {
    padding: 1rem 0;
  }
  
  .blog--element--img {
    height: 150px;
  }
  
  .blog--element--content-time {
    display: flex;
    column-gap: 1rem;
  } 
}

@media only screen and (max-width: 767px) {
  .about--image {
    display: none;
  }

  .navigation--img {
    width: 100%;
}
}

@media only screen and (min-width: 541px) {
  
  .navigation {
    display: flex;
    list-style-type: none;
    padding: 1.5rem;
  }


  /* Navigation */
  .navigation--wrapper {
    display: grid;
    grid-template-columns: 20% 70%;
    gap: 10%;
    position: sticky;
    top: 0px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.85);
  }
  

  .navigation--element {
    padding: 0;
  }

  /* About */

  .about--wrapper {
    padding: 1rem;
  }
  
  .about--personal--description {
    width: 75%;
    line-height: 2;
    padding: 1rem;
    margin-bottom: 2rem;;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }
    


  /* Blogs */

  .blog--wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  
  .blog--grid {
    display: flex;
    flex-direction: column;
  }
  
  .blog--element {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    margin: 1rem 0;
  }
  
  .blog--element--content {
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: space-evenly;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    padding-top: 1rem;
    padding-bottom: 1rem;;
  }

  .blog--element--content--description {
    padding: 1rem 0;
  }
  
  .blog--element--img {
    height: 150px;
  }
  
  .blog--element--content-time {
    display: flex;
    column-gap: 1rem;
  } 
}


@media only screen and (min-width: 768px) {
  
  .navigation {
    display: flex;
    list-style-type: none;
    padding: 1.5rem;
  }

  .navigation--logo {
    width: 30%;
  }


  .navigation--img {
    width: 30%;
  }

  
  .navigation--wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 5rem;
    position: sticky;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.85);
  }

  .about--wrapper {
    display: flex;
    padding: 3rem;
    justify-content: space-between;
  }

  .about--personal--description {
    width: 75%;
    line-height: 2;
    margin: 2rem 0;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }

  .about--image {
    height: 50vh;
  }


  .blog--wrapper {
    display: flex;
    flex-direction: column;
    padding: 3rem;
  }
  
  .blog--grid {
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 10%;
    margin-bottom: 5rem;;
  }
  
  .blog--element {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    margin: 1rem 0;
  }
  
  .blog--element--content {
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: space-evenly;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
  }

  .blog--element--content--description {
    padding: 1rem 0;
  }
  
  .blog--element--img {
    height: 150px;
  }
  
  .blog--element--content-time {
    display: flex;
    column-gap: 1rem;
  }
}


@media only screen and (min-width: 1024px) {
  
  .navigation {
    display: flex;
    list-style-type: none;
    padding: 1.5rem;
  }

  .navigation--logo {
    width: 30%;
  }

  
  
  .navigation--wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 5rem;
    position: sticky;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.85);
  }

  .about--wrapper {
    display: flex;
    padding: 3rem;
    justify-content: space-between;
  }

  .about--personal--description {
    width: 75%;
    line-height: 2;
    margin: 3rem;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }

  .about--image {
    padding-top: 10rem;
    height: 50vh;
  }


  .blog--wrapper {
    display: flex;
    flex-direction: column;
    padding: 3rem;
  }
  
  .blog--grid {
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 10%;
    margin-bottom: 5rem;;
  }
  
  .blog--element {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    margin: 1rem 0;
  }
  
  .blog--element--content {
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: space-evenly;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
  }

  .blog--element--content--description {
    padding: 1rem 0;
  }
  
  .blog--element--img {
    height: 150px;
  }
  
  .blog--element--content-time {
    display: flex;
    column-gap: 1rem;
  }
}


@media only screen and (min-width: 1440px) {
  
  .navigation {
    display: flex;
    list-style-type: none;
    padding: 1.5rem;
  }
  
  .navigation--logo {
    width: 30%;
  }

  .navigation--wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 5rem;
    position: sticky;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.85);
  }

  /* About */

  .about--wrapper {
    display: flex;
    padding: 3rem;
    justify-content: space-between;
  }

  .about--personal--title {
    font-size: 6rem;
  }

  .about--personal--description {
    width: 75%;
    line-height: 2;
    margin: 3rem;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }

  .about--image {
    padding-top: 10rem;
    height: 50vh;
  }

  /* Blog */

  .blog--wrapper {
    display: flex;
    flex-direction: column;
    padding: 3rem;
  }
  
  .blog--grid {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 2%;
    margin-bottom: 5rem;;
  }
  
  .blog--element {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    margin: 1rem 0;
  }
  
  
  .blog--element--content {
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: space-evenly;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
  }

  .blog--element--content--description {
    padding: 1rem 0;
  }
  
  .blog--element--img {
    height: 200px;
  }
  
  .blog--element--content-time {
    display: flex;
    column-gap: 1rem;
  }
}

.book--info {
  font-size: x-small;
}

/* Global NAV */
.navigation--logo {
  display: flex;
}

.navigation--element {
  padding: 0 0.2rem;
}

.navigation--element a {
  padding: 1rem;
  border-bottom: 1px solid black;
}

.navigation--element a:link {
  color: black;
}

.navigation--element a:visited {
  color: black;
}

.blog--button {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.85);
  padding: 1rem;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.85);

  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.socials--wrapper {
  display: flex;
  width: min-content;
}

.socials--element {
  margin: 0 0.25rem;
}

.socials--title {
  margin-top: 5rem;
}



.blog--wrapper {
  background-color: rgba(0,0,0,.8);
  color: white;
}

.blog--wrapper a {
  color: white;
}

/* Concepts */

.concept--wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.04);
}

.concept--description {
  padding: 1rem 0;
}

.concept--content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.concept--img {
  width: 75%;
}

h1 {
  font-size: 45px;
  margin: 1rem 0;
}

.fimga--frame {
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 700px;
  width: auto;
}

.divider {
  margin: 2rem 0;
  border-top: 3px dotted #bbb;
  width: 100%;
}